.not-found{
    text-align: center;

    h1 {
        font-size: 4em;
        margin-bottom: 10px;
    }
}

.no-access{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    text-align: center;
    height: 100%;
}